<template>
  <b-card-code
    no-body
    title="Basic Table"
  >
    <b-table
      responsive="sm"
      :items="items"
    >
      <!-- Columns -->
      <template #cell(id)="data">
        <b-form-checkbox
            v-model="checked"
            :value="data.item.id"
            @input="selectProduct()"
        >
          #{{data.item.id}}
        </b-form-checkbox>
      </template>
    </b-table>
    {{checked}}

    <template #code>
      {{ codeBasic }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BFormCheckbox } from 'bootstrap-vue'
import { codeBasic } from './code'

export default {
  components: {
    BCardCode,
    BTable,
    BFormCheckbox,
  },
  data() {
    return {
      items: [
        {
          id: 1, age: 40, first_name: 'Dickerson', last_name: 'Macdonald', Occupation: 'Job',
        },
        {
          id: 2, age: 21, first_name: 'Larsen', last_name: 'Shaw', Occupation: 'Job', checked: true,
        },
        {
          id: 3, age: 89, first_name: 'Geneva', last_name: 'Wilson', Occupation: 'Bussiness',
        },
        {
          id: 4, age: 38, first_name: 'Jami', last_name: 'Carney', Occupation: 'Bussiness',
        },
        {
          id: 5, age: 40, first_name: 'James', last_name: 'Thomson', Occupation: 'Job',
        },
      ],
      codeBasic,
      checked: [],
    }
  },
  methods: {
    selectProduct: function() {
      this.$emit('onSelectProductsSuccess', this.checked.join(','))
    },
  }
}
</script>
